
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import BulkLookupDropdown from '@/components/pages/services/emailLookup/bulkLookup/BulkLookupDropdown.vue'

export default defineComponent({
  name: 'BulkLookupTable',
  components: { BulkLookupDropdown, TmStatus, TmTable },
})
