import { useModals } from '@/compositions/modals'

type EmailLookup = () => {
  openViewDetailsModal: () => void
  openDeleteModal: (count: number) => void
}

const useEmailLookupOpenModal: EmailLookup = () => {
  const { openModal } = useModals()

  const openViewDetailsModal = () => {
    openModal('emailLookupHistoryDetails', {
      modalTitle: 'Email lookup history details',
    })
  }

  const openDeleteModal = (count = 1) => {
    openModal('confirmation', {
      title: `Delete email lookup${count > 1 ? 's' : ''}`,
      text: `Are you sure you would like to delete selected item${count > 1 ? 's' : ''}? This action cannot be undone.`,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  return {
    openViewDetailsModal,
    openDeleteModal,
  }
}

export default useEmailLookupOpenModal
