
import { defineComponent } from 'vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import useEmailLookupOpenModal from '@/compositions/services/useEmailLookupOpenModal'

export default defineComponent({
  name: 'BulkLookupDropdown',
  components: { TmDropdownItem, TmDropdown, TmTableActionButton },
  props: {
    selected: {
      type: Array,
    },
  },
  setup(props) {
    const { openDeleteModal } = useEmailLookupOpenModal()

    return {
      openDeleteModal: () => openDeleteModal((props.selected || []).length),
    }
  },
})
